import 'vite/modulepreload-polyfill';

import jQuery from 'jquery';
const w = window;

w.$ = w.jQuery = jQuery;

// import 'dotdotdot-js';
import 'foundation-sites/js/foundation/foundation';
import 'foundation-sites/js/foundation/foundation.abide';
import 'foundation-sites/js/foundation/foundation.dropdown';
import 'foundation-sites/js/foundation/foundation.equalizer';
import 'foundation-sites/js/foundation/foundation.interchange';
import 'foundation-sites/js/foundation/foundation.reveal';
import 'jquery-hoverintent';
import 'jquery-migrate';
import 'jquery-textfill/source/jquery.textfill.js';
import 'jquery-ui-dist/jquery-ui';
import 'jquery-validation';
import 'owl.carousel';
import 'slick-carousel';
import './js/common/main.js';
import './js/vendor/jquery.unobtrusive-ajax.min.js';
import './js/vendor/stickyfloat';

import { checkout_form_footer } from './js/block/checkout-form-footer';
import { content_entrance } from './js/block/content-entrance';
import { countdown } from './js/block/countdown';
import { cvr_completion } from './js/block/CVRCompletion';
import { faq_list } from './js/block/faq-list';
import { license_plate_selector } from './js/block/license-plate-selector';
import { links_with_title } from './js/block/links-with-title';
import { product_entrance } from './js/block/product-entrance';
import { product_feature } from './js/block/product-feature';
import { product_presentation } from './js/block/product-presentation';
import { init_subscription } from './js/block/subscription';
import { showMoreInit } from './js/block/text-block';
import { top_banner } from './js/block/top-banner';
import { trust_pilot } from './js/block/trust-pilot';
import { trust_summary } from './js/block/trust-summary';
import { usp_slim } from './js/block/usp-slim';
import { video_carousel } from './js/block/video-carousel';
import { video_module } from './js/block/video-module';
import { completeaddress, completecity, completsugestionsBody } from './js/common/autocomplet';
import { avoid_spaces_input } from './js/common/avoid-spaces-in-input';
import { addtobasket, addtoPo, removefrombasket, updatebasketpreview } from './js/common/baskettools';
import {
    areAllCookiesAccepted,
    CONSENT_CHANGE_EVENT,
    COOKIE_CATEGORIES,
    forceReloadOnCookieConsentChange,
    getCookieConsentValue,
} from './js/common/cookies-consent';
import { custom_date_input } from './js/common/custom-date-input';
import { filter_input_add_validation } from './js/common/filter-input';
import { google_maps } from './js/common/google-maps';
import { initializeHeader } from './js/common/header';
import { payment_selection_page } from './js/common/payment-selection-page';
import { quantity_counter } from './js/common/quantity-counter';
import { comparison_table } from './js/components/comparison';
import { search_page } from './js/components/search-page/search-page';
import { address } from './js/coveredobjects/address';
import { agreement_number } from './js/coveredobjects/agreement-number';
import { add_falck_account_no_validator, customer_information } from './js/coveredobjects/customer-information';
import { existingCustomerOverlay } from './js/coveredobjects/existing-customer-overlay';
import { multiple_persons } from './js/coveredobjects/multiple-persons';
import { vacationaddress } from './js/coveredobjects/vacationaddress';
import { vehicle_information } from './js/coveredobjects/vehicle-information';
import { zipcode_address } from './js/coveredobjects/zipcode-autocomplete';
import { breadcrumb_popup } from './js/selfService/breadcrumb-popup';

export {
    add_falck_account_no_validator,
    address,
    addtobasket,
    addtoPo,
    agreement_number,
    areAllCookiesAccepted,
    avoid_spaces_input,
    breadcrumb_popup,
    checkout_form_footer,
    comparison_table,
    completeaddress,
    completecity,
    completsugestionsBody,
    CONSENT_CHANGE_EVENT,
    content_entrance,
    COOKIE_CATEGORIES,
    countdown,
    custom_date_input,
    customer_information,
    cvr_completion,
    existingCustomerOverlay,
    faq_list,
    filter_input_add_validation,
    forceReloadOnCookieConsentChange,
    getCookieConsentValue,
    google_maps,
    init_subscription,
    initializeHeader,
    license_plate_selector,
    links_with_title,
    multiple_persons,
    payment_selection_page,
    product_entrance,
    product_feature,
    product_presentation,
    quantity_counter,
    removefrombasket,
    search_page, showMoreInit, top_banner,
    trust_pilot,
    trust_summary,
    updatebasketpreview,
    usp_slim,
    vacationaddress,
    vehicle_information,
    video_carousel,
    video_module,
    zipcode_address,
};
