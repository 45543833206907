import { init as initCookiesConsent } from './cookies-consent';

function main() {
    $(() => {
        const globalEventHandlers = () => {
            // Click function for mobile devices for Iphones and Ipads
            $('body').on('touchstart', '*', function () {
                // listen to touch
                const jQueryElement = $(this);
                const element = jQueryElement.get(0); // find tapped HTML element

                if (!element.click) {
                    const eventObj = document.createEvent('MouseEvents');

                    eventObj.initEvent('click', true, true);
                    element.dispatchEvent(eventObj);
                }
            });

            $('.removelink-onload').each(function () {
                $(this).removeAttr('href');
            });
        };

        const globalJQueryExtensions = () => {
            jQuery.fn.singleClick = function (func) {
                $(this).on('click', function (e) {
                    const $el = $(this);

                    if ($el.data('clicked')) {
                        // Previously clicked, stop actions
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        // Mark to ignore next click
                        $el.data('clicked', true);

                        // Callback function
                        func(e);

                        // Unmark after 1 second
                        window.setTimeout(() => {
                            $el.removeData('clicked');
                        }, 200);
                    }
                });

                return this;
            };
        };

        const globalInit = () => {
            $(document).foundation('interchange', 'reflow');
            $(document).foundation('equalizer', 'reflow');
            $(document).foundation('tab', 'reflow');
            $(document).foundation('reveal', 'reflow');
            $(document).foundation('dropdown', 'reflow');

            initCookiesConsent();

            /**
             * Make tabs into slider on mobile phones
             */
            // $('.dotdotdot-wrapper').dotdotdot({ watch: false });

            if ('addEventListener' in document) {
                document.addEventListener(
                    'DOMContentLoaded',
                    () => {
                        // eslint-disable-next-line no-undef
                        FastClick.attach(document.body);
                    },
                    false,
                );
            }
        };

        const getCookie = (cookieName) => {
            const cookie = {};

            document.cookie.split(';').forEach((el) => {
                const [key, value] = el.split('=');

                cookie[key.trim()] = value;
            });

            return cookie[cookieName];
        };

        const smoothScroll = () => {
            function scrollToId() {
                const isCookieConsentSet = getCookie('CookieInformationConsent');
                const hash = window.location.hash.slice(1);

                if (!hash) {
                    return;
                }

                const animateScroll = () =>
                    $('html, body').animate(
                        {
                            scrollTop: $('#js-' + hash).offset().top - 140, // Top nav height
                        },
                        1000,
                    );

                if (window.location.hash && $('#js-' + hash).length && isCookieConsentSet) {
                    return animateScroll();
                }

                window.addEventListener('CookieInformationConsentGiven', animateScroll);

                return null;
            }

            scrollToId();

            $('a[href]:not([role=tab])').on('click', function () {
                const link = $(this).attr('href');

                if (link.indexOf('#') >= 0 && link.length > 1) {
                    window.location.href = link;

                    scrollToId();

                    return false;
                }

                return true;
            });

            $('.js-smoothscroll').parents('.main-section').css('margin', '0px');
        };

        // SleekNote Autocomplete
        if (window.MutationObserver) {
            let sleekNoteObserver;
            // eslint-disable-next-line prefer-const
            let bodyObserver;

            const sleekNoteAutocomplete = ($iframe) => {
                sleekNoteObserver.disconnect();

                const $iframeContent = $($iframe.contents());

                const autocompleteStyles =
                    '.ui-helper-hidden-accessible { display: none; }' +
                    '.ui-widget-content { border: 1px solid #ddd; background: #fff; color: #333; }' +
                    '.ui-menu .ui-menu-item:not(:last-child) { border-bottom: 1px solid #ccc; }' +
                    '.ui-menu .ui-menu-item { position: relative; margin: 0; cursor: pointer; min-height: 0; color: #aaa; }' +
                    '.ui-menu .ui-menu-item a { display: block; padding: 10px; color: inherit; }' +
                    '.ui-state-focus { background: #eaf0f2; font-weight: normal; color: #212121; }' +
                    '.ui-menu .ui-menu-item.ui-state-focus a { color: #212121; }';

                $('<style>' + autocompleteStyles + '</style>').appendTo($iframeContent.find('head'));

                const $inputCompanyName = $iframeContent.find('input[name=company]');
                const $inputPostal = $iframeContent.find('input[name=zipCode]');
                const $inputTDCId = $iframeContent.find('input[name=tdcid]');

                $inputCompanyName.autocomplete({
                    // appendTo: $inputCompanyName.parent(),
                    position: { collision: 'flip' },
                    source(request, response) {
                        let urlTerm = 'https://mdbprodssl.falck.com/api/ErhvervData/Get?name=' + request.term;

                        if ($inputPostal.val() !== '') {
                            urlTerm =
                                'https://mdbprodssl.falck.com/api/ErhvervData/Get?name=' +
                                $inputPostal.val() +
                                ' ' +
                                request.term;
                        }

                        $.get(urlTerm, (data) => {
                            response(
                                $.map(data, (item) => ({
                                    label: item.DisplayText,
                                    value: item,
                                })),
                            );
                        });
                    },
                    focus(event, ui) {
                        const account = ui.item.value;

                        $inputTDCId.val(account.TDCId);
                        $inputCompanyName.val(account.AccountName);

                        event.preventDefault();
                    },
                    select(event, ui) {
                        const account = ui.item.value;

                        $inputTDCId.val(account.TDCId);
                        $inputCompanyName.val(account.AccountName);

                        event.preventDefault();
                    },
                });

                $inputCompanyName.data('ui-autocomplete')._renderItem = (ul, item) =>
                    $('<li>')
                        .append('<a href="#">' + item.label + '</a>')
                        .appendTo(ul);

                $inputCompanyName.data('ui-autocomplete')._resizeMenu = function () {
                    const ul = this.menu.element;

                    ul.outerWidth(this.element.outerWidth());
                };

                $inputCompanyName.on('change', function () {
                    if (!$(this).val()) {
                        $inputTDCId.val('');
                    }
                });
            };

            const sleekNoteObserverCallback = (mutationsList) => {
                bodyObserver.disconnect();

                for (let i = 0; i < mutationsList.length; i++) {
                    const $sleekNoteForm = $('[id^=form-] iframe');

                    if ($sleekNoteForm.length) {
                        sleekNoteAutocomplete($sleekNoteForm);

                        break;
                    }
                }
            };

            const bodyObserverCallback = (mutationsList) => {
                mutationsList.forEach((mutation) => {
                    if (!mutation.addedNodes[0] || mutation.addedNodes[0].id !== 'sleeknote') {
                        return;
                    }

                    sleekNoteObserver = new window.MutationObserver(sleekNoteObserverCallback);
                    sleekNoteObserver.observe(mutation.addedNodes[0], { childList: true });
                });
            };

            bodyObserver = new window.MutationObserver(bodyObserverCallback);
            bodyObserver.observe(document.body, { childList: true });
            setTimeout(() => {
                bodyObserver.disconnect();
            }, 10000);
        }

        // Start load of global event handlers, global JQuery extensions and global init
        globalEventHandlers();
        globalJQueryExtensions();
        globalInit();
        smoothScroll();
    });
}

export default main();
