export function showMoreInit(){
    const ShowMoreBlockClass = 'text-block-show-more';
    const showMore = document.getElementById('show-more');
    const showLess = document.getElementById('show-less');

    if (showMore && showLess) {
        showMore.addEventListener('click', function(e) {
            if(e.target?.parentElement?.classList?.contains(ShowMoreBlockClass)){
                showMore.classList.add('hidden');
                showLess.classList.remove('hidden');
                
            }
        });

        showLess.addEventListener('click', function(e) {
            if(e.target?.parentElement?.classList?.contains(ShowMoreBlockClass)){
                showLess.classList.add('hidden');
                showMore.classList.remove('hidden');

                setTimeout(() => {
                    window.scrollBy({
                        top: showMore.getBoundingClientRect().top - 200,
                        behavior: 'smooth',
                    });
                }, 10);
            }
        });
    }
}